const productMixin = {
	computed: {
		productName() {
			return this.produto.seo_title
		},
		productApplicationsText() {
			return this.produto.nome_aplicacoes
		},
	},
}

export default productMixin
