<template>
	<div v-if="visibility" class="modal">
		<div class="modal__bg" @click="$emit('closeModal')"></div>
		<div class="modal__content" :style="{ maxWidth: width + 'px' }">
			<button class="modal__btnclose" @click="$emit('closeModal')">
				<svg :class="closeBtnColor" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M11.9988 0.748678L11.2494 0L6.01089 5.25641L0.748574 0L0 0.748678L5.26232 6.00509L0.0125634 11.2353L0.761137 11.984L6.01089 6.75377L11.2506 12L12 11.2513L6.75947 6.00509L11.9988 0.748678Z" fill="#001E50" />
				</svg>
			</button>
			<slot></slot>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		visibility: {
			type: Boolean,
			default: false,
		},
		width: {
			type: String,
			default: "883",
		},
		closeBtnColor: {
			type: String,
			default: "",
		},
	},
}
</script>

<style lang="scss" scoped>
@keyframes fadeInUp {
	from {
		opacity: 0;
		transform: translate(-50%, -50%) translateY(100%);
	}

	to {
		opacity: 1;
		transform: translate(-50%, -50%) translateY(0px);
	}
}

.fadeInUp {
	animation-name: fadeInUp;
}
.modal {
	position: fixed;
	inset: 0px;
	background-color: rgba(0, 0, 0, 0.5);
	opacity: 1;
	transition: opacity 300ms ease 0s;
	z-index: 9;
	&__btnclose {
		position: absolute;
		right: 24px;
		top: 16px;
	}
	&__bg {
		position: fixed;
		inset: 0px;
		background-color: rgba(0, 0, 0, 0.5);
		opacity: 1;
		transition: opacity 300ms ease 0s;
	}
	&__content {
		position: absolute;
		left: 50%;
		overflow-y: auto;
		width: 90%;
		background-color: rgb(255, 255, 255);
		box-shadow: rgb(0 0 0 / 10%) 0px 8px 32px 0px, rgb(0 0 0 / 5%) 0px 16px 48px 0px;
		opacity: 1;
		padding: 16px 24px;
		animation-duration: 0.4s;
		animation-name: fadeInUp;
		top: 128px;
		left: 50%;
		transform: translateX(-50%);
		max-height: calc(100% - 226px);
	}
	&.modal__conc {
		z-index: 99999;

		.modal__content {
			padding: 0;
			transform: translate(-50%, -50%);
			top: 50%;
			max-height: 570px;
			overflow: hidden;
		}
		.modal__btnclose {
			display: none;
		}
	}
	&.modal__blue {
		z-index: 99999;

		.modal__content {
			padding: 0;
			transform: translate(-50%, -50%);
			top: 50%;
			max-height: 600px;
			overflow: hidden;
		}
		.modal__btnclose {
			right: 24px;
			svg.white{
				stroke: #fff;
				height: 16px;
				width: 16px;
			}
		}
	}
}

@media (min-height: 900px) {
	.modal {
		&__content {
			padding: 24px 32px;
			top: 50%;
			transform: translate(-50%, -50%) translateY(0);
			max-height: calc(100% - 200px);
		}
		&__btnclose {
			position: absolute;
			right: 32px;
			top: 24px;
			svg {
				height: 24px;
				width: 24px;
			}
		}
	}
}
</style>
