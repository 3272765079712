import { setPredefinedOrder } from "./order";
import { checkProductAvailability } from "./availability";

export function getProductSizes(availableStores, products) {
  let sizes = [];
  function hasCaracteristicas(currentValue) {
    return currentValue.hasOwnProperty("caracteristicas");
  }

  if (availableStores) {
    // Alterar para uma função
    if (products.some(hasCaracteristicas)) {
      for (const hit of products) {
        if (
          Object.keys(checkProductAvailability(hit, availableStores)).length !==
          0
        ) {
          for (const caracteristica of hit.caracteristicas) {
            if (caracteristica["Tamanho"]) {
              sizes.push({
                value: caracteristica["Tamanho"],
                id_produto_variacao: hit.id_produto_variacao,
                tag_stock: hit.tag_estoque,
              });
            }
          }
        }
      }
    }
  }

  if (sizes.length > 0) {
    sizes = setPredefinedOrder(sizes);
  }

  return sizes;
}

export function getBadgeText(stockAvailable) {
  if (stockAvailable === 1) {
    return "Última unidade disponível";
  }
  if (stockAvailable === 2) {
    return "Somente 2 unidades disponíveis";
  }
  if (stockAvailable === 3) {
    return "Restam poucas unidades";
  }
}

export function getDaysText(days) {
  if (days === 1) {
    return "dia útil";
  }
  if (days > 1) {
    return "dias úteis";
  }
}

export function getDealershipsText(quantity) {
  if (quantity === 1) {
    return `${quantity} vendedor com estoque`;
  }
  if(quantity > 1){
    return `${quantity} vendedores com estoque`;
  }
  return ``;
}
