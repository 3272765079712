export function setPredefinedOrder(sizes) {
  const order = [
    "0 - 3 Meses",
    "3 - 6 Meses",
    "6 - 9 Meses",
    "9 - 12 Meses",
    "12 Meses",
    "18 Meses",
    "2500",
    "2600",
    "2700",
    "2800",
    "2900",
    "3000",
    "3100",
    "3200",
    "34",
    "35",
    "36",
    "37",
    "38",
    "39",
    "40",
    "41",
    "42",
    "43",
    "44",
    "PP",
    "P",
    "M",
    "G",
    "GG",
    "GGG",
    "GGGG",
    "GGGGG",
    "GGGGGG",
  ];
  return sizes.sort(function(a, b) {
    return order.indexOf(a.value) - order.indexOf(b.value);
  });
}
